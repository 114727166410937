<template>
  <modal-block modal-id="ModalMedPaidService" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="health-list">
          <template v-slot:title>ДОПОЛНИТЕЛЬНЫЕ ПЛАТНЫЕ УСЛУГИ</template>
        </page-header>
        <div class="content" :class="$screen.device">

          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Наименование услуги
              </th>
              <th class="text-left">
                Стоимость
                руб./сеанс
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="u in uslugi" :key="u.name">
              <td>{{ u.name }}</td>
              <td>{{ u.price }}</td>
            </tr>
            </tbody>
          </v-table>

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalMedPaidService',
  components: {ModalBlock, PageHeader,},
  data: () => ({
    uslugi:[
      {name:'Лабораторные анализы (более 150 видов)',price:'По прейскуранту цен ООО «БиоТест»'},
      {name:'УЗИ органов брюшной полости (печень, желчный пузырь, поджелудочная железа)',price:'700'},
      {name:'УЗИ почек и надпочечников',price:'500'},
      {name:'УЗИ мошонки с оценкой кровотока',price:'500'},
      {name:'УЗИ предстательной железы',price:'500'},
      {name:'УЗИ молочных желез',price:'500'},
      {name:'УЗИ органов малого таза одним датчиком',price:'600'},
      {name:'УЗИ органов малого таза двумя датчиками',price:'700'},
      {name:'УЗИ щитовидной железы',price:'500'},
      {name:'УЗИ брахеоцефальных сосудов',price:'1000'},
      {name:'УЗИ сосудов верхних конечностей',price:'600'},
      {name:'УЗИ сосудов нижних конечностей',price:'1000'},
      {name:'ЭхоКГ (УЗИ сердца)',price:'1000'},
      {name:'Суточное мониторирование ЭКГ+АД',price:'1500'},
      {name:'Урологическая диагностика на аппарате «Делфис»',price:'600'},
      {name:'Кольпоскопия',price:'1000'},
      {name:'Диагностика офтальмологическая (определение остроты зрения с коррекцией, офтальмоскопия глазного дна, определение внутриглазного давления, биомикроскопия (щелевая лампа)',price:'1500'},
      {name:'РЭГ',price:'500'},
      {name:'РВГ',price:'400'},
      {name:'ФВД',price:'350'},
      {name:'Подводный душ-массаж',price:'500'},
      {name:'Душ Шарко',price:'500'},
      {name:'АМОК (автоматическая мониторная очистка кишечника)',price:'700'},
      {name:'Лимфодренажный массаж нижних конечностей (прессотерапия) на аппарате "Медомер"',price:'100'},
      {name:'Сухое дозированное вытяжение позвоночника',price:'600'},
      {name:'Микроклизмы',price:'50'},
      {name:'Озонотерапия',price:''},
      {name:'',price:''},
      {name:'',price:''},
    ]
  }),
}
</script>


<style>
  #ModalMedPaidService{max-width: 650px; width: 100%;}
  #ModalMedPaidService.mobile{width: 100%; height: 100%}
  #ModalMedPaidService>.contentBox{overflow-y: auto}
  #ModalMedPaidService>.contentBox>.content{padding: 20px}
  #ModalMedPaidService>.contentBox>.content.mobile{padding: 20px}



</style>