import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
const moment = require('moment'); require('moment/locale/ru')
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import VueYandexMetrika from 'vue-yandex-metrika'


loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueYandexMetrika, {
    id: 81056569,
    router: router,
    env: process.env.NODE_ENV
})
  .use(VCalendar, {})
  .mixin({created: function () {
      this.$color = this.$vuetify.theme.themes[this.$vuetify.theme.current].colors
      this.$dictionary = store.state.dictionary
      this.$ui = store.state.ui
      this.$screen = store.state.screen
      this.$state = store.state
      this.$commit = store.commit
      this.$dispatch = store.dispatch
      this.$scrollTo = function (elementID){window.scrollTo({top: document.getElementById(`${elementID}`).offsetTop, behavior: 'smooth'})}
      this.$moment = moment
  }})
  .mount('#app')






