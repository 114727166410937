<template>
  <modal-block modal-id="ModalDocs" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="50" o icon="license">
          <template v-slot:title>ЛИЦЕНЗИИ И СЕРТИФИКАТЫ</template>
        </page-header>
        <div class="content" :class="$screen.device">
          <div class="docs">

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:0})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Свидетельство о постановке на учет в налоговом органе</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:1})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Свидетельства о государственной регистрации юридического лица</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:2})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Лицензия на осуществление медицинской деятельности</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:3})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Лицензия на осуществление медицинской деятельности (оборот)</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:4})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Приложение к лицензии</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:5})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Приложение к лицензии (оборот)</span>
            </div>

            <div class="doc" @click="$dispatch('showLightBox', {images:docs, imageIndex:6})">
              <img :src="require('@/assets/icons/doc.png')">
              <span class="label">Свидетельства о присвоение категории</span>
            </div>

          </div>
        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalDocs',
  components: {ModalBlock, PageHeader,},
  data: () => ({
      docs: [
        {src: require('@/assets/photo/docs/inn.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/ogrn.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/lic1.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/lic2.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/pril1.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/pril2.jpg'), width: 850, height: 1170},
        {src: require('@/assets/photo/docs/stars.jpg'), width: 850, height: 1170},
      ]

  }),
}
</script>


<style>
  #ModalDocs{max-width: 650px; width: 100%;}
  #ModalDocs.mobile{width: 100%; height: 100%}
  #ModalDocs>.contentBox{overflow-y: auto}
  #ModalDocs>.contentBox>.content{padding: 20px}
  #ModalDocs>.contentBox>.content.mobile{padding: 20px}
  .docs{display: grid}
  .docs>.doc{cursor:pointer; transition-duration: 100ms; display: grid; grid-template-columns: auto 1fr; align-content: center; align-items: center; column-gap: 10px; padding: 10px; border-bottom: 1px solid #cdc6c6;}
  .docs>.doc:hover{background-color: #efe7e7
  }
  .docs>.doc>img{height: 30px}


</style>