<template>
  <div class="ScrollUp">
    <v-fade-transition>
      <v-icon @click="scrollUp" v-if="$screen.scrollY>($screen.height+100)" large size="40" color="orange darken-2">mdi-arrow-up-bold-box-outline</v-icon>
    </v-fade-transition>
  </div>
</template>


<script></script>

<script>
  export default {
  name: 'ScrollUp',
  data: () => ({}),
  methods:{scrollUp(){
      let scrollHeight = document.getElementById('contentStart').offsetTop
      if(this.$screen.device==='mobile'){scrollHeight = scrollHeight-this.$ui.size.mobileNav}

    document.getElementById('showContent').scrollTo({top: scrollHeight, behavior: 'smooth'})}
    },
  mounted() {},
}
</script>

<style scoped>
  .ScrollUp{position: absolute;z-index: 10;right:20px; bottom:20px; cursor: pointer;}
  .ScrollUp.visible{display: grid}
</style>
