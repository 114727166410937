<template>
  <div class="PageHeader centerContent" :class="`${$screen.device}`" :style="{maxWidth:$ui.size.contentMaxWidth+'px', marginTop:marginTop+'px', marginBottom:marginBottom+'px'}">
    <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
    <div class="title">
      <img v-if="icon" class="icon" :style="{height:iconSize+'px'}" :src="require(`@/assets/icons/${icon}.png`)">
      <h2><slot name="title"></slot></h2>
      <div class="text">
        <p><slot name="text"></slot></p>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: 'PageHeader',
  props:['icon','iconsize','marginTop','marginBottom'],
  data: () => ({
    iconSize:30
  }),
  mounted() {
    let iconSize = this.iconSize
    if(this.iconsize){iconSize = this.iconsize}
    // if(this.$screen.device==='mobile'){iconSize=iconSize-10}
    this.iconSize = iconSize
  }
}
</script>


<style scoped>
  .PageHeader{display: grid;padding: 20px;background-color: #f5eeeb;width: 100%;position: relative;justify-items: center;}
  .PageHeader>.backTexture{position: absolute;  background-repeat: repeat; width: 100%; height: 100%; zoom: 30%; opacity: 2%}

  .PageHeader>.title{justify-items: center;display: grid; align-items: center;; text-align: center}
  .PageHeader>.title>.text{font-size: 14px; text-align: center}
  .PageHeader>.title>.icon{height: 30px;}

/*  MOBILE*/

  .PageHeader.mobile{padding: 10px}
  .PageHeader.mobile>.title{font-size: 12px}
  .PageHeader.mobile>.title>.text{font-size: 11px; text-align: center}
  .PageHeader.mobile>.title>.icon{height: 20px; padding-bottom: 10px}

</style>