<template>
  <modal-block modal-id="ModalMedRules" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="danger">
          <template v-slot:title>УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ ПЛАТНЫХ УСЛУГ</template>
        </page-header>
        <div class="content" :class="$screen.device">
          <p class="subTitle">На основании Постановления правительства «Об утверждении Правил предоставления медицинскими организациями платных медицинских услуг»</p>
          <p class="subTitle">При заключении договора на оказание платных медицинских услуг заказчику предоставляется в доступной форме информация об исполнителе и предоставляемых им медицинских услугах:</p>
          <div class="rules" style="display: grid">
            <li>а) наименование санатория с юридическим и фактическим адресом;</li>
            <li>б) документы, подтверждающие факт внесения сведений в Единый гос. реестр;</li>
            <li>в) сведения о лицензии на осуществление медицинской деятельности;</li>
            <li>г) перечень платных медицинских услуг с указанием цен в рублях;</li>
            <li>д) сведения о медицинских работниках, участвующих в предоставлении платных медицинских услуг, об уровне их профессионального образования и квалификации, режим их работы.</li>
          </div>

          <p class="doc">
            В договоре обязательно указываются сроки предоставления платных медицинских услуг, порядок их оплаты.

            Оплату можно производить всей суммой или за каждую услугу отдельно по мере предоставления.

            Платные медицинские услуги могут предоставляться в полном объеме стандарта медицинской помощи, утвержденного Министерством здравоохранения РФ, либо по просьбе потребителя в виде осуществления отдельных консультаций или медицинских вмешательств, в том числе в объеме, превышающем объем выполняемого стандарта медицинской помощи.

            Заказчику предоставляется информация о методах оказания медицинской помощи, связанных с ним рисках, возможных видах медицинского вмешательства, их последствиях и ожидаемых результатах оказания медицинской помощи.

            До заключения договора заказчик уведомляется о том, что несоблюдение рекомендаций лечащего врача, в том числе назначенного режима лечения, могут снизить качество предоставляемой платной медицинской услуги, повлечь за собой невозможность ее завершения в срок или отрицательно сказаться на состоянии здоровья потребителя.

            Договор заключается в письменной форме в двух экземплярах.

            В случае если при предоставлении платных услуг требуется предоставление на возмездной основе дополнительных медицинских услуг, » не предусмотренных договором, заказчик предупреждается об этом и без его согласия дополнительные медицинских услуги не предоставляются.

            В случае если при предоставлении платных медицинских услуг потребуется предоставление дополнительных медицинских услуг по экстренным показаниям для устранения угрозы жизни потребителя при внезапных-v острых заболеваниях, состояниях, обострениях хронических заболеваний, такие медицинские услуги оказываются без взимания платы.

            При оказании платных медицинских услуг соблюдаются установленные законодательством РФ требования к оформлению и ведению медицинской документации и учетных и отчетных статистических форм и сроки их предоставления.
          </p>
        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalMedRules',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalMedRules{max-width: 650px; width: 100%;}
  #ModalMedRules.mobile{width: 100%; height: 100%}
  #ModalMedRules>.contentBox{overflow-y: auto}
  #ModalMedRules>.contentBox>.content{padding: 20px}
  #ModalMedRules>.contentBox>.content.mobile{padding: 20px}
  #ModalMedRules>.contentBox>.content>.subTitle{font-weight: bold; font-size: 14px}
  #ModalMedRules>.contentBox>.content>.rules{font-size: 14px; padding-top: 10px;; padding-bottom: 10px;}
  #ModalMedRules>.contentBox>.content>.doc{font-size: 14px; padding-top: 10px;; padding-bottom: 10px;}


</style>