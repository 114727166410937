<template>

  <modal-block modal-id="ModalMobileMenu" close-button="yes">
    <template v-slot:content>
      <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
      <div class="contentBox">
        <div class="MobileMenuContent" :style="{paddingTop:$ui.size.mobileNav+'px'}">
          <div class="links">
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/"  :block="true" color="white">ГЛАВНАЯ</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/rooms"  :block="true" color="white">НОМЕРА И ЦЕНЫ</v-btn>
            <v-btn variant="text"  @click="$commit('closeModal','ModalMobileMenu')" to="/treatment" :block="true" color="white">ЛЕЧЕНИЕ И ПУТЕВКИ</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/spa" :block="true" color="white">СПА-ЗОНА</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/leisure" :block="true" color="white">ПИТАНИЕ И ДОСУГ</v-btn>
          </div>
        </div>
      </div>
    </template>

  </modal-block>




</template>

<script>
  import ModalBlock from "@/components/ModalBlock";
  export default {
  name: 'ModalMobileMenu',
    components: {ModalBlock},
    data: () => ({showMenu:false}),
  methods:{toggle(){this.showMenu = !this.showMenu}},
  mounted() {},
}
</script>

<style>

  #ModalMobileMenu{width: 100%; height: 100%; background-color: rgba(85, 85, 85, 0.69); position: relative}
  #ModalCovid>.contentBox{overflow-y: auto; }
  #ModalCovid>.contentBox>.content{padding: 40px}
  #ModalCovid>.contentBox>.content.mobile{padding: 20px}
  #ModalMobileMenu>.backTexture{position: absolute;  background-repeat: repeat; width: 100%; height: 100%; zoom: 40%; opacity: 5%}


  .MobileMenuCloseButton{right: 10px;top:10px; position: absolute}

  .MobileMenuContent{width: 100%; height: 100%; background-color: rgba(20, 80, 34, 100); display: grid;   grid-template-rows: auto 1fr; padding-top: 20px}
  .MobileMenuContent>.logo{height: 100px; display: grid; justify-content: center; margin-bottom: 20px}

</style>
