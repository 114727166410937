<template>
  <modal-block modal-id="ModalRoomPrice" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="ruble-circle-underline">
          <template v-slot:title>ЦЕНЫ НА РАЗМЕЩЕНИЕ</template>
          <template v-slot:text><span  style="font-size: 20px; font-weight: lighter;">НОМЕР  </span><span style="font-size: 20px; font-weight: bold; text-transform: uppercase">«{{$store.state.rooms[$store.state.bookingRoom].name}}»</span></template>
        </page-header>
        <div class="content" :class="$screen.device">
          <div id="RoomPrice" :class="$screen.device">
            <div class="dateNav noSelect">
              <div @click="nav('minus')" class="btn"><img :src="require('@/assets/icons/left-arrow.png')"></div>
              <div class="label">{{price[activePrice].label}}</div>
              <div @click="nav('plus')" class="btn"><img :src="require('@/assets/icons/right-arrow.png')"></div>
            </div>
            <div  class="table">
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/adult.png')"><span class="name">ВЗРОСЛЫЙ</span><span class="desc">(ОСНОВНОЕ МЕСТО)</span></div>
                <div class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].adultMain}} руб.</span></div>
              </div>
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/adult.png')"><span class="name">ВЗРОСЛНЫЙ</span><span class="desc">(ДОП МЕСТО)</span></div>
                <div class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].adultDop}} руб.</span></div>
              </div>
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/kids.png')"><span class="name">РЕБЕНОК (4-14 лет)</span><span class="desc">(ОСНОВНОЕ МЕСТО)</span></div>
                <div class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].childMain}} руб.</span></div>
              </div>
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/kids.png')"><span class="name">РЕБЕНОК (4-14 лет)</span><span class="desc">(ОСНОВНОЕ МЕСТО)</span></div>
                <div class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].childDop}} руб.</span></div>
              </div>
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/door.png')"><span class="name">ОДНОМЕСТНОЕ</span><span class="desc">(ОДИН ЧЕЛОВЕК В НОМЕРЕ)</span></div>
                <div class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].single}} руб.</span></div>
              </div>

            </div>
            <v-btn @click="this.$commit('bookFromPrice')"  :style="{fontSize:$screen.width<375?'9px':'12px'} " :block="true" variant="contained-flat" :border="false" height="40"  size="small" color="green">
              <p style="color: white">ЗАБРОНИРОВАТЬ ЭТОТ НОМЕР</p>
            </v-btn>
          </div>

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalRoomPrice',
  components: {ModalBlock, PageHeader,},
  methods:{
    nav(n){
      let total= this.price.length
      let activePrice = this.activePrice+1
      if(n==='plus' && activePrice<total){this.activePrice=this.activePrice+1}
      if(n==='minus' && activePrice>1){this.activePrice=this.activePrice-1}


    }
  },
  data: () => ({
    activePrice:0,
    price:[
      {label:'1 АПРЕЛЯ - 31 ИЮЛЯ',start:'01.04.2022',end:'31.07.2022',rooms:{
        standard:{adultMain:3100,adultDop:2400,childMain:2400,childDop:1800,single:5500},
        comfort:{adultMain:3300,adultDop:2600,childMain:2600,childDop:1900,single:5900},
        studio:{adultMain:3600,adultDop:2800,childMain:2800,childDop:2100,single:6400},
        lux:{adultMain:4500,adultDop:3600,childMain:3600,childDop:3600,single:8100},
        }},
      {label:'1 АВГУСТА - 31 ОКТЯБРЯ',start:'01.08.2022',end:'31.10.2022',rooms:{
        standard:{adultMain:3300,adultDop:2600,childMain:2600,childDop:1900,single:5900},
        comfort:{adultMain:3500,adultDop:2800,childMain:2800,childDop:2100,single:6300},
        studio:{adultMain:3800,adultDop:3000,childMain:3000,childDop:2200,single:6800},
        lux:{adultMain:4800,adultDop:3800,childMain:3800,childDop:2800,single:8600},
        }},
      {label:'1 НОЯБРЯ - 25 ДЕКАБРЯ',start:'01.11.2022',end:'25.12.2022',rooms:{
        standard:{adultMain:2900,adultDop:2300,childMain:2300,childDop:1700,single:5200},
        comfort:{adultMain:3100,adultDop:2400,childMain:2400,childDop:1800,single:5500},
        studio:{adultMain:3400,adultDop:2700,childMain:2700,childDop:2000,single:6100},
        lux:{adultMain:4200,adultDop:3300,childMain:3300,childDop:2500,single:7500},
        }},
    ]
  }),
}
</script>


<style>
  #ModalRoomPrice{max-width: 650px; width: 100%;}
  #ModalRoomPrice.mobile{width: 100%; height: 100%}
  #ModalRoomPrice>.contentBox{overflow-y: auto}
  #ModalRoomPrice>.contentBox>.content{}
  #ModalRoomPrice>.contentBox>.content.mobile{padding: 20px}

  #RoomPrice{}
  #RoomPrice>.dateNav{display: grid; grid-template-columns: auto 1fr auto; background-color: white; align-items: center; border-bottom: 2px solid #3e3e3e}
  #RoomPrice>.dateNav>.btn{cursor:pointer; transition-duration:100ms; display: grid; background-color: #bd9f7a; height: 60px; width: 60px; align-items: center; justify-items: center}
  #RoomPrice.desktop>.dateNav>.btn:active{background-color: #e5c8a5;}
  #RoomPrice>.dateNav>.btn:hover{ background-color: #d2b28b;}
  #RoomPrice>.dateNav>.btn>img{height: 20px; filter: invert()}
  #RoomPrice>.dateNav>.label{text-align: center;}

  #RoomPrice>.table{}
  #RoomPrice>.table>.item{
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    border-bottom: 1px solid #a19e9e;
  }

  #RoomPrice>.table>.item>.label{padding: 10px;font-size: 14px; background-color: #fdfcfc; display: grid; align-items: center; grid-auto-flow: column; justify-content: flex-start; column-gap: 4px}
  #RoomPrice>.table>.item>.label>.icon{height: 20px;}
  #RoomPrice>.table>.item>.label>.name{font-size: 15px}
  #RoomPrice>.table>.item>.label>.desc{font-size: 10px; color: #7b7878;}

  #RoomPrice>.table>.item>.price{padding: 10px; display: grid; align-items: center; background-color: #f1e7e7;}


</style>