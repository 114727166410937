<template>
  <modal-block modal-id="ModalMedPersonal" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="doctor">
          <template v-slot:title>НАШ МЕДИЦИНСКИЙ ПЕРСОНАЛ</template>
          <template v-slot:text>
            Качество медицинского обслуживания в санатории обеспечивается усилием слаженного коллектива врачей и медицинских сестер.
            Для оказания медицинских услуг на высоком профессиональном уровне с использованием новейших достижений медицинской науки в санаторий привлекаются лучшие специалисты
          </template>
        </page-header>
        <div class="content" :class="$screen.device">
          <div class="personal">
            <p class="category">СПЕЦИАЛИСТЫ</p>
            <div class="item">
              <p class="name">Хасанова Мадина Хусейновна</p>
              <p class="position">Заместитель ген. директора по медицинской части, Врач терапевт, Врач кардиолог</p>
            </div>
            <div class="item">
              <p class="name">Биджиева Асият Ахматовна</p>
              <p class="position">Врач офтальмолог</p>
            </div>
            <div class="item">
              <p class="name">Владимирова Лора Сергеевна</p>
              <p class="position">Врач гинеколог</p>
            </div>
            <div class="item">
              <p class="name">Гудзь Елена Александровна</p>
              <p class="position">Врач невролог, Врач функциональной диагностики</p>
            </div>
            <div class="item">
              <p class="name">Евтухов Александр Иванович</p>
              <p class="position">Врач гастроэнтеролог</p>
            </div>
            <div class="item">
              <p class="name">Исрафилов Исрафил Сефибекович</p>
              <p class="position">Врач физиотерапевт</p>
            </div>
            <div class="item">
              <p class="name">Микулович Лев Владимирович</p>
              <p class="position">Врач уролог</p>
            </div>
            <div class="item">
              <p class="name">Хатуев Заур Казбекович</p>
              <p class="position">Врач ультразвуковой диагностики</p>
            </div>
            <div class="item">
              <p class="name">Саматов Уллубий Гюсенович</p>
              <p class="position">Врач оториноларинголог</p>
            </div>

            <p class="category" style="margin-top: 20px">МЕДИЦИНСКИЙ ПЕРСОНАЛ</p>

            <div class="item">
              <p class="name">Камбарова Эльмира Расиковна</p>
              <p class="position">Старшая медицинская сестра Медицинская сестра физиотерапевтического кабинета</p>
            </div>
            <div class="item">
              <p class="name">Никитина Оксана Александровна</p>
              <p class="position">Медицинская сестра физиотерапевтического кабинета</p>
            </div>
            <div class="item">
              <p class="name">Шаханов Ахмат Амырбиевич</p>
              <p class="position">Медицинский брат массажного кабинета</p>
            </div>
            <div class="item">
              <p class="name">Савина Марина Алексеевна</p>
              <p class="position">Медицинская сестра физиотерапевтического кабинета</p>
            </div>
            <div class="item">
              <p class="name">Чотчаева Мадина Исхаковна</p>
              <p class="position">Медицинская сестра физиотерапевтического кабинета</p>
            </div>
            <div class="item">
              <p class="name">Шидакова Нусипат Биляловна</p>
              <p class="position">Заместитель ген. директора по медицинской части, Врач терапевт, Врач кардиолог</p>
            </div>
            <div class="item">
              <p class="name">Джанибекова Зулифа Орузбиевна</p>
              <p class="position">Медицинская сестра дежурная</p>
            </div>
            <div class="item">
              <p class="name">Текеева Мадина Энверовна</p>
              <p class="position">Медицинская сестра дежурная</p>
            </div>
            <div class="item">
              <p class="name">Шидакова Нусипат Биляловна</p>
              <p class="position">Медицинская сестра дежурная</p>
            </div>


          </div>
        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalMedPersonal',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalMedPersonal{max-width: 650px; width: 100%;}
  #ModalMedPersonal.mobile{width: 100%; height: 100%}
  #ModalMedPersonal>.contentBox{overflow-y: auto}
  #ModalMedPersonal>.contentBox>.content{padding: 20px}
  #ModalMedPersonal>.contentBox>.content.mobile{padding: 20px}
  #ModalMedPersonal>.contentBox>.content>.personal{display: grid; justify-items: center}
  #ModalMedPersonal>.contentBox>.content>.personal>.category{font-size: 20px; width: 100%; text-align: center; font-weight: lighter; border-bottom: 2px solid #3e3e3e;margin-bottom: 14px;}
  #ModalMedPersonal>.contentBox>.content>.personal>.item{display: grid;margin-bottom: 10px; justify-items: center; text-align: center; padding-bottom: 10px; border-bottom: 1px solid #bd9f7a}
  #ModalMedPersonal>.contentBox>.content>.personal>.item>.name{font-size: 20px;}
  #ModalMedPersonal>.contentBox>.content>.personal>.item>.position{font-size: 14px}

</style>