<template>
  <modal-block modal-id="ModalMedPrice" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="ruble-circle-underline">
          <template v-slot:title>ПРАЙС ЛИСТ МЕДИЦИНСКИХ УСЛУГ</template>
        </page-header>
        <div class="content" :class="$screen.device">
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Наименование услуги
              </th>
              <th class="text-left">
                Стоимость
                руб./сеанс
              </th>
            </tr>
            </thead>
            <tbody v-for="c in price" :key="c.category">
            <tr><td colspan="2" style="text-align: center; font-weight: bold; text-transform: uppercase">{{c.category}}</td></tr>
            <tr v-for="p in c.items" :key="p.name">
              <td>{{ p.name }}</td>
              <td>{{ p.price }}</td>
            </tr>
            </tbody>
          </v-table>
        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalMedPrice',
  components: {ModalBlock, PageHeader,},
  data: () => ({
    price:[
      {category:'Терапия',items:[
          {name:'Первичный прием терапевта',price:'1200'},
          {name:'Повторный прием терапевта',price:'700'},
        ]},
      {category:'Кардиология',items:[
          {name:'Первичный прием кардиолога',price:'700'},
          {name:'Повторный прием кардиолога',price:'500'},
          {name:'Электрокардиография',price:'350'},
          {name:'Суточное мониторирование (ЭКГ+АД)',price:'1300'},
        ]},
      {category:'Гинекология',items:[
          {name:'Первичный прием гинеколога',price:'700'},
          {name:'Прием гинеколога - эндокринолога',price:'700'},
          {name:'Повторный прием гинеколога',price:'500'},
          {name:'Консультация врача эндокринолога-гинеколога',price:'700'},
          {name:'Кольпоскопия (расширенная)',price:'1000'},
          {name:'Аппаратная физиотерапия «Андрогин»',price:'200'},
        ]},
      {category:'Урология',items:[
          {name:'Первичный прием уролога-андролога',price:'700'},
          {name:'Повторный прием',price:'500'},
          {name:'Спермограмма',price:'1100'},
          {name:'Инстиляция мочевого пузыря и уретры',price:'100'},
          {name:'Диагностика на аппарате «Делфис»',price:'600'},
          {name:'Процедуры на аппаратах  «Андрогин», «Ласт», «Матрикс», «Трима»',price:'200'},
          {name:'Урологический массаж',price:'200'},
          {name:'Доплерографическое исследование  кровотока полового члена с медикаментозной индукцией эрекции',price:'500'},
        ]},
      {category:'Офтальмология',items:[
          {name:'Первичный прием офтальмолога',price:'700'},
          {name:'Повторный прием офтальмолога',price:'500'},
          {name:'Диагностика (определение остроты зрения с коррекцией, офтальмоскопия глазного дна, определение внутриглазного давления, биомикроскопия (щелевая лампа)',price:'1500'},
        ]},
      {category:'Неврология',items:[
          {name:'Первичный прием невролога',price:'700'},
          {name:'Повторный прием врача невролога',price:'500'},
          {name:'Дозированное сухое вытяжение позвоночника (1 сеанс)',price:'600'},
        ]},
      {category:'Гастроэтерология',items:[
          {name:'Первичный прием гастроэнтеролога',price:'700'},
          {name:'Повторный прием гастроэнтеролога',price:'500'},
          {name:'Микроклизмы',price:'250'},
          {name:'АМОК № 1',price:'900'},
          {name:'Очистительная клизма',price:'250'},
        ]},
      {category:'Озонотерапия',items:[
          {name:'Внутривенное введение озона',price:'400'},
          {name:'Внутримышечное введение озона',price:'250'},
          {name:'Внутрикожное введение озона (позвоночник, суставы)',price:'200 одна зона'},
          {name:'Наружная озонотерапия  ( "пилотка", "сапожки")',price:'200'},
        ]},
      {category:'УЗИ',items:[
          {name:'УЗИ органов брюшной полости (печень, желчный пузырь, поджелудочная  железа , селезенка)',price:'700'},
          {name:'УЗИ почек и надпочечников',price:'400'},
          {name:'УЗИ мошонки с оценкой кровотока',price:'400'},
          {name:'УЗИ предстательной железы',price:'400'},
          {name:'УЗИ молочной железы и лимфатических узлов',price:'400'},
          {name:'УЗИ органов малого таза одним датчиком',price:'500'},
          {name:'УЗИ органов малого таза двумя датчиком',price:'600'},
          {name:'УЗИ щитовидной железы ',price:'400'},
          {name:'ЭхоКГ (УЗИ сердца)',price:'1000'},
          {name:'УЗИ брахеоцефальных сосудов',price:'1000'},
          {name:'УЗИ артерий и вен верхних конечностей',price:'600'},
          {name:'УЗИ артерий и вен нижних конечностей',price:'1000'},
        ]},
      {category:'Физиотерапия',items:[
          {name:'Дарсонваль',price:'200'},
          {name:'Грязелечение (аппликации) 1 единица',price:'200'},
          {name:'Диадинамические токи',price:'300'},
          {name:'Магнитотерапия',price:'300'},
          {name:'Ультразвуковая терапия',price:'300'},
          {name:'Фонофорез',price:'300'},
          {name:'Электро-вакуумное воздействие (1 зона)',price:'300'},
          {name:'Электрофорез',price:'300'},
          {name:'УФО местный',price:'150'},
          {name:'Магнитное поле на ЛОР-органы',price:'250'},
          {name:'КВЧ',price:'300'},
          {name:'Лазеротерапия',price:'300'},
          {name:'Гальванические токи',price:'300'},
          {name:'Компрессионная  терапия и лимфодренаж',price:'300'},
          {name:'Сухие углекислые ванны ',price:'250'},
        ]},
      {category:'Ванны',items:[
          {name:'Ванны жемчужные, йодобромные, хвойные, бишофитные',price:'400'},
          {name:'Углекисло-минеральные ванны',price:'4'},
        ]},
      {category:'Гидропатия',items:[
          {name:'Подводный душ-массаж',price:'600'},
          {name:'Циркулярный душ',price:'350'},
          {name:'Восходящий душ',price:'350'},
          {name:'Душ Шарко',price:'500'},
        ]},
      {category:'Инъекции',items:[
          {name:'Внутривенное вливание (капельное)',price:'300'},
          {name:'Внутривенное вливание (струйное)',price:'200'},
          {name:'Внутримышечная, подкожная',price:'150'},
        ]},
      {category:'Ингаляция',items:[
          {name:'Ингаляция (щелочная, масляная, лекарственная)',price:'150'},
        ]},
      {category:'Массаж',items:[
          {name:'Массаж оздоровительный (1,5 ед.)',price:'300'},
          {name:'Общий (полностью все тело)',price:'2000'},
          {name:'Спина (полностью)',price:'800'},
          {name:'Руки',price:'500'},
          {name:'Голова',price:'300'},
          {name:'Ноги',price:'600'},
        ]},
      {category:'Натуральная терапия',items:[
          {name:'Спелеокамера (соляная шахта)',price:'300 один сеанс'},
          {name:'Естественный солярий',price:'200 один день'},
          {name:'Кислородный коктейль',price:'150'},
          {name:'Фитотерапия',price:'150 один день'},
          {name:'Минеральная вода (нарзан, славяновская, ессентуки-4)',price:'150 один день'},
        ]},
      {category:'СПА-комплекс',items:[
          {name:'Базовый СПА-пакет (плавательный бассейн, гидромассажный бассейн,  финская сауна, хамам, купель, вибромассаж стоп)',price:'500 руб. за час'},
          {name:'Сеанс инфракрасной сауны',price:'200'},
          {name:'Безконтактный водный массаж',price:'350'},
          {name:'Плавательный бассейн (для проживающих в санатории)',price:'0'},
        ]},
      {category:'Развлечение и досуг',items:[
          {name:'Бильярд, теннис, караоке, концертные программы, и т.п.',price:'0'},
        ]},
    ],
  }),
}
</script>


<style>
  #ModalMedPrice{max-width: 650px; width: 100%;}
  #ModalMedPrice.mobile{width: 100%; height: 100%}
  #ModalMedPrice>.contentBox{overflow-y: auto}
  #ModalMedPrice>.contentBox>.content{padding: 20px}
  #ModalMedPrice>.contentBox>.content.mobile{padding: 20px}


</style>