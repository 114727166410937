<template>
  <div class="LandingBlock" :class="`${$screen.device}`" :style="{height:height+'px',}">
    <div class="backgroundImage" :style="{backgroundImage: `url(${require('@/assets/photo/kislovodsk1.jpg')})`,height:height+$ui.size.navPanel[$screen.device]+'px'}"></div>
    <div class="dimmer"></div>
    <div class="content centerContent" :style="{maxWidth:$ui.size.contentMaxWidth+'px'}">
      <div class="logo"><img :src="require(`@/assets/img/logo.svg`)"></div>
      <v-btn @click="scrollDown()" width="200" variant="contained-flat" :border="false"
             :style="{fontSize:$screen.width<375?'9px':'12px'} " height="40" size="small" color="primary">
        <span  style="color: white">ПОДРОБНЕЕ</span>
      </v-btn>
      <p class="title">САНАТОРИЙ "ЭЛИТА" - КИСЛОВОДСК</p>
      <p class="subtitle">ОФИЦИАЛЬНЫЙ САЙТ</p>

    </div>
    <div class="scrollContainer">
      <v-fade-transition>
        <div v-if="$screen.scrollY<$screen.height/2" class="scroll" @click="scrollDown()"><v-img src="@/assets/img/scroll.gif" contain height="100"/></div>
      </v-fade-transition>
    </div>


  </div>
</template>

<script>

export default {
  name: 'LandingBlock',
  props:[],
  data: () => ({
    startHeight:undefined,
    sideDirection:'left',
  }),
  computed:{
    height(){
      let height = this.$screen.height
      if(this.$screen.device==='mobile'){height = this.$screen.startHeight}
      return height
    }
  },
  methods:{
    scrollDown(){
      let scrollHeight = this.height
      if(this.$screen.device==='mobile'){scrollHeight = scrollHeight-this.$ui.size.mobileNav}

      document.getElementById('showContent').scrollTo({top: scrollHeight, behavior: 'smooth'})
    }
  }
}
</script>


<style scoped>
  .LandingBlock{
    display: grid;
    width: 100%;
    background-color: #3e3e3e;
    align-items: flex-end;
    justify-items: center;
    position: relative;
    z-index: 0;
      grid-template-rows: 1fr auto;
  }

  .LandingBlock>.scrollContainer{
    display: grid;
    cursor: pointer;
    height: 100px;
    width: 100px;
    margin-bottom: 30px;
  }

  .LandingBlock>.dimmer{
    width: 100%; height: 100%;
    position: absolute;
    z-index: 0;
    background-color: #000000;
    opacity: 0.3;
    pointer-events: none;
  }

  .LandingBlock>.backgroundImage{
    position: absolute;
    width: 100%;
    z-index: 0;
    top:0;
    background-position: center;
    background-size: cover;
  }

  .LandingBlock>.content{
    width: 100%;
    display: grid;
    justify-items: center;
    color: white;
    text-align: center;
    z-index: 1;

  }

  .LandingBlock>.content>.logo{ width: 200px; padding-bottom: 30px}

  .LandingBlock.mobile>.content>.logo{width: 150px; padding-bottom: 20px;}


  .content>.title{font-size: 11px; padding-top: 20px}
  .content>.subtitle{font-size: 11px; padding-bottom: 14px}

</style>