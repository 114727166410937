import { createStore } from 'vuex'

const bodyScrollLock = require('body-scroll-lock');
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import('photoswipe')


let lightbox = new PhotoSwipeLightbox({initialZoomLevel: 'fit', secondaryZoomLevel: 'fit', showHideAnimationType: 'fade', pswpModule: () => import('photoswipe'),});
lightbox.init();




export default createStore({
  state: {
    lightbox: lightbox,
    devMode:true,
    lightBoxSource:undefined,
    debug:false,
    enterPoint:undefined,
    mobileMenuShow:false,
    ui:{
      narrow:false,
      colors: undefined,
      size:{
        scrollbarWidth:5,
        contentMaxWidth:1200,
        mobileNav:60,
        navPanel:{
          desktop:90,
          mobile:0
        },
        text:{
          title:16,
          subtitle:14,
          main:12,
          sm:10,
          xs:8,
        }
      }
    },
    dictionary:{
      date:{
        days:["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресение"],
        days_short:["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
        months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
      },
    },
    screen:{
      width:undefined,
      height:undefined,
      startHeight:undefined,
      orientation:undefined,
      device:undefined,
      scrollY:0,
      contentOffset:undefined,
      scrollYbeforeLock:0,
      scrollX:0,
      menuStickyPoint:false,
    },
    temp:{},
    currentRoute:'main',
    bookingRoom:'standard',
    rooms: {
      standard:{
        alias:'standard',
        name:'Стандарт',
        rooms:'Однокомнатный',
        floor:'ЛАМИНАТ',
        size:16,
        guests:'2 ГОСТЯ',
        description:'Класический двухместный номер «СТАНДАРТ». Номера этой категории расположены в первом корпусе.',
        facilities: ['twoSingleBed','oneSofa','shower','fan','robe', 'tea','minibar','wifi','tv'],
      },
      comfort:{
        alias:'comfort',
        name:'Комфорт',
        rooms:'Однокомнатный',
        floor:'ЛАМИНАТ',
        size:20,
        guests:'2 ГОСТЯ + 2 ДОП. МЕСТА',
        description:'Улучшенный двухместный номер «КОМФОРТ». Номера этой категории расположены в первом корпусе.',
        facilities: ['twoSingleBed','oneSofa','shower','tea','robe','fan','minibar','wifi','tv'],
      },
      lux:{
        name:'Люкс',
        alias:'lux',
        rooms:'Двухкомнатный',
        floor:'ЛАМИНАТ',
        size:35,
        guests:'2 ГОСТЯ + 2 ДОП. МЕСТА',
        description:'Просторный двухкомнатный номер «ЛЮКС». Номера этой категории расположены во втором корпусе.',
        facilities: ['oneDoubleBed','oneSofa','bath','tea','robe','fan','minibar','wifi','tv'],
      },
      studio:{
        alias:'studio',
        name:'Студио',
        rooms:'Двухкомнатный',
        floor:'ЛАМИНАТ',
        size:22,
        guests:'2 ГОСТЯ + 2 ДОП. МЕСТА',
        description:'Просторный двухместный номер «СТУДИО». Номера этой категории расположены во первом корпусе.',
        facilities: ['oneDoubleBed','oneSofa','bath','tea','robe','fan','minibar','wifi','tv'],
      }
    },
    galleries:{
      rooms:{
        standard:[
          {largeURL:require('@/assets/photo/rooms/standard/standard-1.jpg'), thumbnailURL:require('@/assets/photo/rooms/standard/standard-1t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/standard/standard-2.jpg'), thumbnailURL:require('@/assets/photo/rooms/standard/standard-2t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/standard/standard-3.jpg'), thumbnailURL:require('@/assets/photo/rooms/standard/standard-3t.jpg'), width: 900, height: 1200},
        ],
        studio:[
          {largeURL:require('@/assets/photo/rooms/studio/studio-1.jpg'), thumbnailURL:require('@/assets/photo/rooms/studio/studio-1t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/studio/studio-2.jpg'), thumbnailURL:require('@/assets/photo/rooms/studio/studio-2t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/studio/studio-3.jpg'), thumbnailURL:require('@/assets/photo/rooms/studio/studio-3t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/studio/studio-4.jpg'), thumbnailURL:require('@/assets/photo/rooms/studio/studio-4t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/studio/studio-5.jpg'), thumbnailURL:require('@/assets/photo/rooms/studio/studio-5t.jpg'), width: 900, height: 1200},
        ],
        lux:[
          {largeURL:require('@/assets/photo/rooms/lux/lux-1.jpg'), thumbnailURL:require('@/assets/photo/rooms/lux/lux-1t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/lux/lux-2.jpg'), thumbnailURL:require('@/assets/photo/rooms/lux/lux-2t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/lux/lux-3.jpg'), thumbnailURL:require('@/assets/photo/rooms/lux/lux-3t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/lux/lux-4.jpg'), thumbnailURL:require('@/assets/photo/rooms/lux/lux-4t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/lux/lux-5.jpg'), thumbnailURL:require('@/assets/photo/rooms/lux/lux-5t.jpg'), width: 900, height: 1200},
        ],
        comfort:[
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-1.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-1t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-2.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-2t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-3.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-3t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-4.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-4t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-5.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-5t.jpg'), width: 900, height: 1200},
          {largeURL:require('@/assets/photo/rooms/comfort/comfort-6.jpg'), thumbnailURL:require('@/assets/photo/rooms/comfort/comfort-6t.jpg'), width: 900, height: 1200},
        ]
      }
    },
    facilities:{
      oneSingleBed:{icon:'bed-single', name:'1 ОДНОСПАЛЬНАЯ КРОВАТЬ'},
      twoSingleBed:{icon:'bed-single', name:'2 ОДНОСПАЛЬНЫЕ КРОВАТИ'},
      oneDoubleBed:{icon:'bed-double', name:'ДВУСПАЛЬНАЯ КРОВАТЬ'},
      oneSofa:{icon:'sofa', name:'ДИВАН-КРОВАТЬ'},
      twoSofa:{icon:'sofa', name:'2 ДИВАНА-КРОВАТИ'},
      bath:{icon:'bath', name:'СОБСТВЕННАЯ ВАННА'},
      shower:{icon:'shower', name:'ДУШ'},
      fan:{icon:'fan', name:'ФЕН'},
      tea:{icon:'hot-cup', name:'ЧАЙНЫЙ НАБОР'},
      towel:{icon:'towel', name:'ПОЛОТЕНЦА'},
      minibar:{icon:'minibar', name:'ХОЛОДИЛЬНИК'},
      tv:{icon:'tv', name:'ТВ'},
      wifi:{icon:'wifi', name:'WI-FI'},
      floorCarpet:{icon:'floor', name:'КОВРОЛИН'},
      floorWood:{icon:'floor', name:'ЛАМИНАТ'},
      robe:{icon:'robe', name:'ХАЛАТЫ'},

    },
    modals:{
      ModalMobileMenu:false,
      ModalRules:false,
      ModalDocs:false,
      ModalContacts:false,
      ModalCovid:false,
      ModalPartners:false,
      ModalReservation:false,
      ModalMedRules:false,
      ModalMedPersonal:false,
      ModalMedPaidService:false,
      ModalMedPrice:false,
      ModalRoomPrice:false,
    },
    overlayReady:false,
    overlayLock:false,
    price:[
      {label:'1 АПРЕЛЯ - 31 ИЮЛЯ',start:'01.04.2022',end:'31.07.2022',rooms:{
          standard:{adultMain:3100,adultDop:2400,childMain:2400,childDop:1800,single:5500},
          comfort:{adultMain:3300,adultDop:2600,childMain:2600,childDop:1900,single:5900},
          studio:{adultMain:3600,adultDop:2800,childMain:2800,childDop:2100,single:6400},
          lux:{adultMain:4500,adultDop:3600,childMain:3600,childDop:3600,single:8100},
        }},
      {label:'1 АВГУСТА - 31 ОКТЯБРЯ',start:'01.08.2022',end:'31.10.2022',rooms:{
          standard:{adultMain:3300,adultDop:2600,childMain:2600,childDop:1900,single:5900},
          comfort:{adultMain:3500,adultDop:2800,childMain:2800,childDop:2100,single:6300},
          studio:{adultMain:3800,adultDop:3000,childMain:3000,childDop:2200,single:6800},
          lux:{adultMain:4800,adultDop:3800,childMain:3800,childDop:2800,single:8600},
        }},
      {label:'1 НОЯБРЯ - 25 ДЕКАБРЯ',start:'01.11.2022',end:'25.12.2022',rooms:{
          standard:{adultMain:2900,adultDop:2300,childMain:2300,childDop:1700,single:5200},
          comfort:{adultMain:3100,adultDop:2400,childMain:2400,childDop:1800,single:5500},
          studio:{adultMain:3400,adultDop:2700,childMain:2700,childDop:2000,single:6100},
          lux:{adultMain:4200,adultDop:3300,childMain:3300,childDop:2500,single:7500},
        }},
    ],
    minPrice:{}
  },
  getters: {},
  mutations: {
    bookFromPrice(state){
      state.modals.ModalRoomPrice = false
      state.modals.ModalBooking = true
    },
    updateMinPrice(state){
      let minPrice={
        standard:state.price[0].rooms.standard.adultMain,
        comfort:state.price[0].rooms.comfort.adultMain,
        studio:state.price[0].rooms.studio.adultMain,
        lux:state.price[0].rooms.lux.adultMain
      }

      state.minPrice = minPrice

    },
    setOverlayReady(state){state.overlaReady = true},
    setEnterPoint(state,route){state.enterPoint = route},
    updateColors(state,colors){state.ui.colors = colors},
    updateBookingRoom(state,room){
      state.bookingRoom = room
    },
    updateContentOffset(state){
      //ОБНОВЛЕНИЕ ТЕКУЩЕГО МАКСИМАЛЬНОЙ ПРОКРУТКИ
      state.screen.contentOffset = document.body.scrollHeight
    },
    updateScroll(state,offset){
      //ОБНОВЛЕНИЕ ТЕКУЩЕГО ПОЛОЖЕНИЕ SCROLL ПО ВЕРТИКАЛИ
      if(offset){state.screen.scrollY=offset;
      if(state.screen.scrollY>=state.screen.height+1){state.screen.menuStickyPoint=true}else{state.screen.menuStickyPoint=false}}
    },
    updateScrollLock(state){
      state.screen.scrollYbeforeLock = window.scrollY
    },
    updateScreen(state){
      // ОПРЕДЕЛЕНИЕ РАЗМЕРОВ ОКНА, ТИПА УСТРОЙСТВА И ОРИЕНТАЦИИ ЭКРАНА
      const width = window.innerWidth; state.screen.width = window.innerWidth
      const height = window.innerHeight; state.screen.height = window.innerHeight
      if(state.screen.startHeight===undefined){state.screen.startHeight=height}
      let narrowState = state.ui.narrow
      let device = 'desktop'; let orientation = 'landscape';if(width<height){orientation = 'portrait'}
      if(width<1000){device='tablet'} if(width<700){device='mobile'}
      if(state.screen.orientation !== orientation){state.screen.orientation = orientation; if(state.devMode && state.debug){console.log(`Ориентация экрана изменилась на - ${orientation}`)}}
      if(state.screen.device !== device){state.screen.device = device; if(state.devMode && state.debug){console.log(`Тип устройства изменилась на - ${device}`)}}
      state.screen.orient= orientation

      let narrow
      if(width<state.ui.size.contentMaxWidth){narrow = true; this.commit('updateTextSize')}else{narrow=false}
      if(narrowState !== narrow){
        state.ui.narrow = !state.ui.narrow
        if(!narrow){
          if(state.temp.textSize){state.ui.size.text = Object.assign({}, state.temp.textSize)}
        }
      }

    },
    updateTextSize(state){
      if(!state.temp.textSize){state.temp.textSize=Object.assign({}, state.ui.size.text)}
      let difSize =((state.ui.size.contentMaxWidth-state.screen.width)/state.screen.width)*100
      let textSizeVariables = Object.keys(state.ui.size.text)
      textSizeVariables.forEach(t=>{
        state.ui.size.text[t] = state.temp.textSize[t] * (1 - difSize / 100)
      })
    },
    updateRoute(state,route){state.currentRoute = route},
    toggleOverlay(state,overlay){
      state.overlayLock = !state.overlayLock
      state.overlays[overlay]=!state.overlays[overlay]
      if(state.screen.scrollYbeforeLock>state.screen.height){
        document.getElementById('Nav').style.position='absolute'
        document.getElementById('Nav').style.width='100%'
        document.getElementById('Nav').style.top=state.screen.scrollYbeforeLock+'px'
      }
    },
    openModal(state,modal) {
      state.screen.scrollYbeforeLock = state.screen.scrollY
      document.getElementById('OverlaysView').style.top='0px'
      document.getElementById('OverlaysView').style.zIndex='1000'
      state.overlayLock = true
      state.modals[modal] = true
    },

    closeModal(state,modal){
      state.overlayLock = false
      state.modals[modal] = false
      setTimeout(()=>{document.getElementById('OverlaysView').style.zIndex='0'},300)
    }
  },
  actions: {
    scrollToContent(){document.getElementById('showContent').scrollTo({top: document.getElementById('contentStart').offsetTop, behavior: 'smooth'})},
    lockScroll({commit}){
      this.state.overlayLock = !this.state.overlayLock
      let offset = this.state.screen.scrollY-90
      let scrollY= `-${offset}`
      commit('updateScrollLock')
      document.body.style.height = this.state.screen.height + 'px';
      document.body.style.overflow = 'hidden';
      document.getElementById('mainWindow').style.marginTop=scrollY+'px'
      if(offset>this.state.screen.height){
        document.getElementById('Nav').style.position='absolute'
        document.getElementById('Nav').style.width='100%'
        document.getElementById('Nav').style.top='0px'
      }

    },
    unlockScroll(){enableBodyScroll('#mainWindow');},
    showLightBox(state,{images,imageIndex}){
      let sIndex = 0; if(imageIndex){sIndex=imageIndex}
      lightbox.loadAndOpen(sIndex,images)
    }
  },
  modules: {}
})
