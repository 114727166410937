<template>
  <modal-block modal-id="ModalContacts" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="50" o icon="woman-call">
          <template v-slot:title>КОНТАКТНАЯ ИНФОРМАЦИЯ</template>
        </page-header>
        <div class="content" :class="$screen.device">

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalContacts',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalContacts{max-width: 650px; width: 100%;}
  #ModalContacts.mobile{width: 100%; height: 100%}
  #ModalContacts>.contentBox{overflow-y: auto}
  #ModalContacts>.contentBox>.content{padding: 20px}
  #ModalContacts>.contentBox>.content.mobile{padding: 20px}

</style>