<template>
  <modal-block modal-id="ModalBooking" close-button="yes">
    <template v-slot:content>

      <div class="bookingBox">
        <page-header iconsize="50" o icon="hotel-receptionist">
          <template v-slot:title>ЗАПРОС НА БРОНИРОВАНИЕ</template>
          <template v-slot:text><span  style="font-size: 20px; font-weight: lighter;">НОМЕР  </span><span style="font-size: 20px; font-weight: bold; text-transform: uppercase">«{{$store.state.rooms[$store.state.bookingRoom].name}}»</span></template>
        </page-header>

        <div class="dates">
          <div class="date">
            <div class="label"><img class="icon" :src="require('@/assets/icons/date.png')"><p class="name">ДАТА ЗАЕЗДА</p></div>
            <v-date-picker color="yellow" :min-date='checkInMinDay' v-model="checkIn" :input-debounce="500">
              <template v-slot="{ inputValue, inputEvents }">
                <input  class="pickerInput bg-white borderrounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly
                />
              </template>
            </v-date-picker>
          </div>

          <div class="date">
            <div class="label"><img class="icon" :src="require('@/assets/icons/date.png')"><p class="name">ДАТА ВЫЕЗДА</p></div>
            <v-date-picker color="yellow" :min-date='checkOutMinDay' v-model="checkOut" :input-debounce="500">
              <template v-slot="{ inputValue, inputEvents }">
                <input  class="pickerInput bg-white borderrounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="client">
          <input placeholder="ВАШЕ ИМЯ"  class="pickerInput" v-model="firstName"/>
          <input placeholder="ВАШЕ ФАМИЛИЯ"  class="pickerInput" v-model="secondName"/>
          <input placeholder="НОМЕР ТЕЛЕФОНА"  class="pickerInput" v-model="phone"/>
          <input placeholder="E-MAIL (НЕОБЯЗАТЕЛЬНО)"  class="pickerInput" v-model="email"/>
        </div>


        <div class="submit">
          <v-btn :block=true @click="sendMail" width="200" variant="contained-flat" :border="false"
                 :style="{fontSize:$screen.width<375?'9px':'12px'} " height="40" size="small" color="primary">
            <span  style="color: white">ОТПРАВИТЬ</span>
          </v-btn>
        </div>


      </div>

    </template>

  </modal-block>

</template>

<script>

const axios = require('axios');
import ModalBlock from "@/components/ModalBlock";
import PageHeader from "@/components/PageHeader";
export default {
  name: 'ModalBooking',
  components: {PageHeader, ModalBlock},
  data: () => ({
    checkIn:'',
    checkOut:'',
    checkInMinDay:'',
    checkOutMinDay:'',
    firstName:'',
    secondName:'',
    phone:'',
    email:'',

  }),
  mounted() {

    this.checkInMinDay =this.$moment().add(1,'day').toDate();
    this.checkOutMinDay = this.$moment().add(3,'day').toDate();

  },
  methods:{
    sendMail(){
      let room = this.$store.state.bookingRoom[0]
      setTimeout(()=>{
        axios.get('https://reserv.san-elita.ru/system/assets/postman/sendmail.php', {
          params: {
            room_type:room,
            startDate:this.checkIn,
            endDate:this.checkOut,
            first_name:this.firstName,
            second_name:this.secondName,
            phone:this.phone,
            email:this.email,
          }
        })
                .then(function (response) {
                  console.log(response);
                })

      },200)


    },
    dateChange(picker){
      let minStay = 3
      if(picker==='checkIn'){
        if(this.checkOut!==''){
          let b = this.$moment(this.checkIn);
          let a = this.$moment(this.checkOut);
          let dif = a.diff(b, 'days')
          if(dif<minStay){this.checkOut =''}
        }
      }

    }
  },
  watch:{
    checkIn() {this.dateChange('checkIn')},
    checkOut() {this.dateChange('checkOut')}
  }
}
</script>


<style>
  #ModalBooking{max-width: 650px; width: 100%;}
  #ModalBooking.mobile{width: 100%; height: 100%}
  .bookingBox{width: 100%; }

  .bookingBox>.dates{display: grid; grid-template-columns: 1fr 1fr; column-gap: 10px; padding: 20px ;padding-top: 10px; padding-bottom: 10px;}
  .bookingBox>.dates>.date{display: grid; row-gap: 8px; padding-top: 10px;}
  .bookingBox>.dates>.date>.label{grid-template-columns: auto auto; display: grid; column-gap: 8px; justify-content: center; width: 100%}
  .bookingBox>.dates>.date>.label>.icon{height: 18px;}
  .bookingBox>.dates>.date>.label>.name{font-size: 14px;}
  .bookingBox>.dates>.date{}
  .pickerInput{width: 100%; height: 40px; text-align: center; background-color: white}
  .bookingBox>.client{margin:10px 0px; display: grid; row-gap: 10px; padding: 20px; padding-top: 0px; padding-bottom: 0px}
  .bookingBox>.submit{padding: 20px; padding-top: 0px}
</style>